import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useThrottle } from "hooks/throttle";
import useStyles from "./styles";

export interface Props {
  width: number;
  height: number;
  count: number;
  itemHeight: number;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement[];
  className?: string;
  itemClassName?: string;
  offsetTop?: number;
  offsetLeft?: number;
  itemsBeyond?: number;
  enabled?: boolean;
  header?: React.ReactElement;
  applyWidthToChild?: boolean;
}

const VirtualList = (props: Props) => {
  const {
    width,
    height,
    itemHeight,
    children,
    scrollContainerRef,
    className,
    itemClassName,
    header,
    enabled = true,
    count = 0,
    offsetTop = 0,
    offsetLeft = 0,
    itemsBeyond = 3,
    applyWidthToChild = false
  } = props;

  const classes = useStyles();
  const headerRef = useRef<HTMLDivElement>();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [itemsInView, setItemsInView] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: Math.ceil(height / itemHeight) });

  const isInView = index => {
    return index >= itemsInView.start && index < itemsInView.end;
  };

  const handleScroll = () => {
    if (scrollContainerRef.current && enabled) {
      const container = scrollContainerRef.current;
      const scrollTop = scrollContainerRef.current.scrollTop;
      const containerHeight = container.getBoundingClientRect().height;
      const inView = {
        start:
          Math.floor((scrollTop - headerHeight) / itemHeight) - itemsBeyond - 1,
        end:
          Math.ceil((scrollTop - headerHeight + containerHeight) / itemHeight) +
          itemsBeyond
      };

      setItemsInView(inView);
    }
  };

  const throttledHandleScroll = useThrottle(handleScroll, 83, {
    leading: false,
    trailing: true
  });

  useEffect(() => {
    document.addEventListener("scroll", throttledHandleScroll, {
      once: false,
      capture: true,
      passive: false
    });

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    setHeaderHeight(headerRef?.current?.offsetHeight || 0);
  }, [headerRef.current]);

  return (
    <div className={classes.root}>
      <div
        ref={scrollContainerRef}
        className={clsx(className, classes.container)}
        style={{
          height,
          width,
          marginLeft: offsetLeft
        }}
      >
        <div
          style={{
            height: headerHeight + offsetTop + count * itemHeight
          }}
        >
          {header && React.cloneElement(header, { ref: headerRef })}
          {React.Children.map(
            children,
            (child, index) =>
              isInView(index) && (
                <div
                  key={child.key}
                  className={clsx(
                    classes.item,
                    `${className}__item`,
                    `${className}__item--${index % 2 === 0 ? "even" : "odd"}`,
                    itemClassName
                  )}
                  style={{
                    top: headerHeight + offsetTop + index * itemHeight,
                    height: itemHeight,
                    width: applyWidthToChild ? width : undefined
                  }}
                >
                  {child}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default VirtualList;
